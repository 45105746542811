<template>
  <div>
    <div class="row">
      <div class="col-12">
        <p class="h2">Modifier livrable N°1</p>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-md-6 col-12">
            
              <b-form-group
                label="Nom du livrable:"
                label-for="nested-street"
                label-cols-sm="3"
                label-align-sm="left"
              >
                <b-form-input value="Livrable 1" id="nested-street"></b-form-input>
              </b-form-group>

              <b-form-group
                label="Tâche:"
                label-for="nested-city"
                label-cols-sm="3"
                label-align-sm="left"
              >
                <b-form-select value="1" :options="[{value: 1, text:'Tâche 1'},{value: 2, text:'Tâche 2'},{value: 3, text:'Tâche 3'}]"></b-form-select>
              </b-form-group>

              <b-form-group
                label="Phase initiative:"
                label-for="nested-state"
                label-cols-sm="3"
                label-align-sm="left"
              >
                <b-form-select value="1" :options="[{value: 1, text:'Phase 1'},{value: 2, text:'Phase 2'},{value: 3, text:'Phase 3'}]"></b-form-select>
              </b-form-group>

              <b-form-group
                label="Initiative:"
                label-for="nested-country"
                label-cols-sm="3"
                label-align-sm="left"
              >
                <b-form-select value="1" :options="[{value: 1, text:'Initiative 1'},{value: 2, text:'Initiative 2'},{value: 3, text:'Initiative 3'}]"></b-form-select>
              </b-form-group>

          </div>
          <div class="col-md-6 col-12">
              <b-form-group
                label="Feuille de route:"
                label-for="nested-country"
                label-cols-sm="3"
                label-align-sm="left"
              >
                <b-form-select value="1" :options="[{value: 1, text:'Fdr 1'},{value: 2, text:'Fdr 2'},{value: 3, text:'Fdr 3'}]"></b-form-select>
              </b-form-group>
              <b-form-group
                label="Objectif:"
                label-for="nested-country"
                label-cols-sm="3"
                label-align-sm="left"
              >
                <b-form-select value="1" :options="[{value: 1, text:'Objectif 1'},{value: 2, text:'Objectif 2'},{value: 3, text:'Objectif 3'}]"></b-form-select>
              </b-form-group>
              <b-form-group
                label="Vision:"
                label-for="nested-country"
                label-cols-sm="3"
                label-align-sm="left"
              >
                <b-form-select value="1" :options="[{value: 1, text:'Vision 1'},{value: 1, text:'Vision 2'},{value: 1, text:'Vision 3'}]"></b-form-select>
              </b-form-group>
          </div>
          <div class="col-12">
          <hr>
              <b-button variant="secondary">Confirmer</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>