<template>
  <section class="tables">
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body p-2">
            <b-table
              :items="items"
              id="table-list"
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :busy="loading"
              show-empty
              bordered
              striped
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-2"></b-spinner>
                  <strong>Chargement...</strong>
                </div>
              </template>

              <template v-slot:cell(actions)="data">
                <TableActions
                  :actions="['delete']"
                  @showItem="$emit('showItem', data.item)"
                  @editItem="$emit('editItem', data.item)"
                  @deleteItem="deleteItem(data.item.id)"
                />
              </template>

              <template #empty>
                <div class="text-center text-gray">Table vide</div>
              </template>
              <template v-slot:cell(responsable)="data">
                {{ data.value }}
                <TableResponsable :responsable="data.item.collaborateur" />
              </template>

              <template v-slot:cell(task)="data">
                <b-link
                  v-if="data.value"
                  :to="{
                    name: 'show-task',
                    params: {
                      id: data.value.id,
                      slug: data.value.slug || 'abcde',
                    },
                  }"
                  class="d-inline-block text-truncate"
                  style="max-width: 150px"
                >
                  {{ data.item.task.libelle }}
                </b-link>
              </template>
              <template v-slot:cell(phase)="data">
                <span>
                  {{
                    data.item.task.phase ? data.item.task.phase.libelle : "-"
                  }}
                </span>
              </template>
              <template v-slot:cell(name)="data">
                <b-link target="_blank" :href="IMG_URL + data.item.fileUrl">
                  {{ data.value }}
                </b-link>
              </template>

              <template v-slot:cell(initiatives)="data">
                <b-link
                  v-if="data.item.task.phase.initiatives"
                  :to="{
                    name: 'show-initiative',
                    params: {
                      id: data.item.task.phase.initiatives.id,
                      slug: data.item.task.phase.initiatives.slug,
                    },
                  }"
                >
                  {{ data.item.task.phase.initiatives.libelle }}
                </b-link>
                <span v-else> -</span>
              </template>
              <template v-slot:cell(status)="data">
                <span>{{ data.item.task.status }}</span>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="table-list"
              align="right"
            >
            </b-pagination>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import TableActions from "@/components/custom/TableActions.vue";
import TableResponsable from "@/components/custom/TableResponsable.vue";
import Swal from "sweetalert2";
import { IMG_URL } from "@/helpers/services/api";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: { TableActions, TableResponsable },
  data: function () {
    return {
      sortBy: "name",
      perPage: 10,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      IMG_URL,
    };
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  props: {
    fields: Array,
    items: Array,
    loading: Boolean,
  },
  methods: {
    deleteItem(id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("livrable/deleteLivrable", id).then(() => {
            this.$store.dispatch("livrable/fetchAllLivrables").then(() => {
              Swal.fire("Supprimé!", "", "success");
            });
          });
        }
      });
    },
  },
};
</script>
