<template>
  <div class="">
    <div class="align-items-center">
      <div
        style="border-bottom: 1px solid black"
        class="
          w-100
          d-flex
          align-items-center
          justify-self-start justify-content-between
        "
      >
        <h4 class="card-title mt-4 mb-1">Liste des livrables</h4>
        <div class="d-flex justify-content-end col-md-6 p-0">
          <b-form-input
            type="email"
            v-model="searchQuery.name"
            placeholder="Nom livrable"
            class="col-md-4"
          ></b-form-input>

          <v-select
            label="value"
            v-model="searchQuery.responsable"
            :options="responsables"
            :reduce="(responsable) => responsable.id"
            placeholder="Responsable"
            class="ml-2 col-sm-12 col-md-4 p-0 mb-0 m-0"
          >
            <template #no-options> Liste vide </template>
          </v-select>
          <v-select
            label="value"
            v-model="searchQuery.status"
            :options="status"
            placeholder="Statut"
            class="ml-2 col-sm-12 col-md-4 p-0 mb-0 m-0"
          >
            <template #no-options> Liste vide </template>
          </v-select>
        </div>
      </div>
    </div>

    <div class="row my-3">
      <div class="col-12">
        <DeliverableTable
          :loading="loadingData"
          @showItem="showItem"
          @editItem="showEditDeliverable = !showEditDeliverable"
          :items="livrableList"
          :fields="deliverable.tableFields"
        />
      </div>
    </div>

    <div v-if="showAddDeliverable" to="modals-xyz-548">
      <Modal @close="showAddDeliverable = !showAddDeliverable">
        <AddDeliverable />
      </Modal>
    </div>

    <div v-if="showEditDeliverable" to="modals-xyz-548">
      <Modal @close="showEditDeliverable = !showEditDeliverable">
        <EditDeliverable />
      </Modal>
    </div>

    <div v-if="showDeliverable" to="modals-xyz-548">
      <Modal @close="showDeliverable = !showDeliverable">
        <ShowDeliverable :delivrable="selectedDelivrable" />
      </Modal>
    </div>
  </div>
</template>

<script>
import Modal from "@/components/custom/Modal.vue";
import DeliverableTable from "./components/DeliverableTable.vue";
import AddDeliverable from "./components/AddDeliverable.vue";
import EditDeliverable from "./components/EditDeliverable.vue";
import ShowDeliverable from "./components/ShowDeliverable.vue";
import { mapGetters } from "vuex";
import { httpClient } from "../../../helpers/services/HttpClient";
//import moment from "moment";
export default {
  components: {
    DeliverableTable,
    Modal,
    AddDeliverable,
    EditDeliverable,
    ShowDeliverable,
  },
  data: () => ({
    loadingData: true,
    firstname: Array(),
    deliverable: {
      tableItems: [],
      tableFields: [
        { key: "name", label: "Livrable", sortable: true },
        { key: "status", label: "Statut", sortable: true },
        { key: "responsable", label: "Responsable", sortable: true },

        { key: "task", label: "Tâche", sortable: true },
        { key: "phase", label: "Phase initiative", sortable: true },
        { key: "initiatives", label: "Initiative", sortable: true },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },
          sortable: false,
        },
      ],
    },
    searchQuery: {
      name: "",
      responsable: "",
      status: "",
    },
    filteredFdrs: [],
    arra: [],
    selectedDelivrable: {},
    filterationData: [
      {
        type: "txt",
        value: "",
        placeholder: "Nom livrable",
        searchQuery: "livrable",
      },
      {
        type: "txt",
        value: "",
        placeholder: "Responsable",
        searchQuery: "responsable",
      },
      {
        type: "select",
        value: "",
        placeholder: "Statut",
        searchQuery: "status",
      },
    ],
    showAddDeliverable: false,
    showEditDeliverable: false,
    showDeliverable: false,
  }),
  created() {
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("status/fetchTaskStatus");
    this.$store.dispatch("livrable/fetchAllLivrables").then(() => {
      this.loadingData = false;
    });
  },
  methods: {
    showDeliverabl: async function (visionId) {
      this.showGoals = !this.showGoals;
      const response = await httpClient.get(
        `transformation/livrables/${visionId}`
      );
      window.console.table(response.data);
      this.goalsTableList = response.data;
    },
    showItem: function (item) {
      this.selectedDelivrable = item;
      this.showDeliverable = true;
    },
  },
  computed: {
    ...mapGetters("livrable", ["LIVRABLES"]),
    ...mapGetters("users", ["RESPONSABLES"]), //get resposables
    ...mapGetters("status", ["TASK_STATUS"]), //get all status
    livrableList() {
      return this.LIVRABLES.filter(
        (livrable) =>
          livrable.name
            .toLowerCase()
            .indexOf(this.searchQuery.name.toLowerCase()) > -1 &&
          (this.searchQuery.responsable
            ? livrable.task.responsable.id === this.searchQuery.responsable
            : true) &&
          (this.searchQuery.status
            ? livrable.task.status
                .toLowerCase()
                .indexOf(this.searchQuery.status.toLowerCase()) > -1
            : true)
      );
    },
    responsables() {
      //return array of responsables
      var data = this.RESPONSABLES.map((responsable) => ({
        // value: `/api/users/${responsable.id}`,
        id: responsable.id,
        value: `${responsable.firstname} ${responsable.lastname}`,
      }));
      return data;
    },
    status() {
      //return array of responsables
      var status = this.TASK_STATUS;
      return status;
    },
  },

  watch: {
    LIVRABLES: function () {
      this.tableItems = this.LIVRABLES;
    },
  },
};
</script>

<style>
.vs--searchable .vs__dropdown-toggle {
  cursor: text;
  height: 100% !important;
  background: white;
}
.vs__search {
  color: #cfcfcf;
}
</style>
