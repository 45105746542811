var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-10 p-0",staticStyle:{"margin-top":"-58px"}},[_c('p',{staticClass:"h2 text-secondary m-3"},[_c('i',{staticClass:"mdi mdi-send",staticStyle:{"font-size":"22px","color":"#072648 !important"}}),_c('span',{staticClass:"font-weight-light ml-1",staticStyle:{"font-size":"18px"}},[_vm._v("Livrable : ")]),_c('span',{staticStyle:{"font-size":"22px"}},[_vm._v(_vm._s(_vm.delivrable.name))])])])]),_c('hr',{staticClass:"mt-0"}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 col-12"},[_c('div',{staticClass:"shdow",staticStyle:{"background-color":"rgba(164, 164, 91, 0.5)"}},[_c('div',{staticClass:"d-flex flex-wrap justify-content-between align-items-center"},[_c('p',{staticClass:"h2 text-secondary"},[_vm._v("Livrable")]),_c('a',{staticClass:"text-secondary",staticStyle:{"font-size":"30px"},attrs:{"href":_vm.IMG_URL + _vm.delivrable.fileUrl,"target":"_blank"}},[_c('i',{staticClass:"mdi mdi-download"})]),_c('hr',{staticClass:"w-100"})]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 col-12"},[_c('div',{staticClass:"my-3"},[_c('span',{staticClass:"font-weight-bold mr-3"},[_vm._v("Nom de livrables:")]),_c('span',[_vm._v(_vm._s(_vm.delivrable.name))])]),_c('div',{staticClass:"my-3"},[_c('span',{staticClass:"font-weight-bold mr-3"},[_vm._v("Tâche:")]),(_vm.delivrable.task)?_c('b-link',{staticClass:"d-inline-block my-auto text-truncate",staticStyle:{"max-width":"150px"},attrs:{"to":{
                      name: 'show-task',
                      params: {
                        id: _vm.delivrable.task.id,
                        slug: _vm.delivrable.task.slug || 'abcde',
                      },
                    }}},[_vm._v(_vm._s(_vm.delivrable.task.libelle))]):_vm._e()],1)]),_c('div',{staticClass:"col-md-6 col-12"},[_c('div',{staticClass:"my-3"},[_c('span',{staticClass:"font-weight-bold mr-3"},[_vm._v("Phase initiative:")]),(_vm.delivrable.task && _vm.delivrable.task.phase)?_c('span',[_vm._v(_vm._s(_vm.delivrable.task.phase.libelle))]):_vm._e()]),_c('div',{staticClass:"my-3"},[_c('span',{staticClass:"font-weight-bold mr-3"},[_vm._v("Initiative:")]),(_vm.delivrable.task && _vm.delivrable.task.phase)?_c('b-link',{attrs:{"to":{
                      name: 'show-task',
                      params: {
                        id: _vm.delivrable.task.phase.initiatives.id,
                        slug:
                          _vm.delivrable.task.phase.initiatives.slug || 'abcde',
                      },
                    }}},[_vm._v(_vm._s(_vm.delivrable.task.phase.initiatives.libelle))]):_vm._e()],1)])])]),_c('br'),_c('div',{staticClass:"shdow",staticStyle:{"background-color":"rgba(107, 236, 144, 0.5)"}},[_vm._m(0),(
                _vm.delivrable.task &&
                _vm.delivrable.task.phase &&
                _vm.delivrable.task.phase.initiatives
              )?_c('div',{staticClass:"row"},_vm._l((_vm.delivrable.task.phase.initiatives
                  .feuilleRoute.objectifs),function(objectif,index){return _c('div',{key:index,staticClass:"col-12 d-flex"},[(index === 0)?_c('div',{staticClass:"col-md-8 p-0 col-12"},[_c('div',{staticClass:"my-3"},[_c('span',{staticClass:"font-weight-bold mr-3"},[_vm._v("Nom de visions:")]),_c('b-link',{attrs:{"to":{
                        name: 'show-vision',
                        params: {
                          id: objectif.vision.id,
                          slug: objectif.vision.slug || 'abcde',
                        },
                      }}},[_vm._v(_vm._s(objectif.vision ? objectif.vision.libelle : "n/a"))])],1)]):_vm._e(),(index === 0)?_c('div',{staticClass:"col-md-4 p-0 col-12"},[_c('div',{staticClass:"my-3"},[_c('span',{staticClass:"font-weight-bold mr-3"},[_vm._v("Date:")]),_c('span',[_vm._v(_vm._s(objectif.vision.createdAt ? _vm.moment( objectif.vision.createdAt, "DD/MM/YYYY" ).format("DD/MM/YYYY") : "n/a"))])])]):_vm._e()])}),0):_vm._e()])]),_c('br'),_c('div',{staticClass:"col-md-6 col-12"},[_c('div',{staticClass:"shdow",staticStyle:{"background-color":"rgba(103, 179, 183, 0.5)"}},[_vm._m(1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"my-3"},[_c('span',{staticClass:"font-weight-bold mr-3"},[_vm._v("Nom de feuille de route:")]),(
                      _vm.delivrable.task &&
                      _vm.delivrable.task.phase &&
                      _vm.delivrable.task.phase.initiatives &&
                      _vm.delivrable.task.phase.initiatives.feuilleRoute
                    )?_c('b-link',{attrs:{"to":{
                      name: 'show-fdr',
                      params: {
                        id: _vm.delivrable.task.phase.initiatives.feuilleRoute.id,
                        slug:
                          _vm.delivrable.task.phase.initiatives.feuilleRoute
                            .slug || 'abcde',
                      },
                    }}},[_vm._v(_vm._s(_vm.delivrable.task.phase.initiatives.feuilleRoute.name))]):_vm._e()],1),_c('div',{staticClass:"my-3"},[_c('span',{staticClass:"font-weight-bold mr-3"},[_vm._v("Statut:")]),(
                      _vm.delivrable.task &&
                      _vm.delivrable.task.phase &&
                      _vm.delivrable.task.phase.initiatives &&
                      _vm.delivrable.task.phase.initiatives.feuilleRoute &&
                      _vm.delivrable.task.phase.initiatives.feuilleRoute.status ==
                        'Terminé'
                    )?_c('span',{staticClass:"badge badge-success"},[_vm._v(" "+_vm._s(_vm.delivrable.task.phase.initiatives.feuilleRoute.status))]):_c('span',{staticClass:"badge badge-Warning"},[_vm._v(" "+_vm._s(_vm.delivrable.task.phase.initiatives.feuilleRoute.status))])])])])]),_c('br'),_c('div',{staticClass:"shdow",staticStyle:{"background-color":"#e6e3fc"}},[_vm._m(2),(
                _vm.delivrable.task &&
                _vm.delivrable.task.phase &&
                _vm.delivrable.task.phase.initiatives
              )?_c('div',{staticClass:"row"},_vm._l((_vm.delivrable.task.phase.initiatives
                  .feuilleRoute.objectifs),function(objectif,index){return _c('div',{key:index,staticClass:"col-12 d-flex"},[_c('div',{staticClass:"my-3 p-0 col-md-8 col-12"},[_c('span',{staticClass:"font-weight-bold mr-3"},[_vm._v("Nom de l'objectif "+_vm._s(index + 1)+":")]),_c('b-link',{attrs:{"to":{
                      name: 'show-objectif',
                      params: {
                        id: objectif.id,
                        slug: objectif.slug || 'abcde',
                      },
                    }}},[_vm._v(_vm._s(objectif ? objectif.libelle : "n/a"))])],1),_c('div',{staticClass:"col-md-4 p-0 col-12"},[_c('div',{staticClass:"my-3"},[_c('span',{staticClass:"font-weight-bold mr-3"},[_vm._v("Description:")]),_c('span',[_vm._v(_vm._s(objectif.desription ? objectif.desription : "n/a"))])])])])}),0):_vm._e()])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-wrap justify-content-between align-items-center"},[_c('p',{staticClass:"h2 text-secondary"},[_vm._v("Vision")]),_c('hr',{staticClass:"w-100"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-wrap justify-content-between align-items-center"},[_c('p',{staticClass:"h2 text-secondary"},[_vm._v("Feuille de route")]),_c('hr',{staticClass:"w-100"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-wrap justify-content-between align-items-center"},[_c('p',{staticClass:"h2 text-secondary"},[_vm._v("Objectif")]),_c('hr',{staticClass:"w-100"})])
}]

export { render, staticRenderFns }