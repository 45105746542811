<template>
  <div>
    <div class="row">
      <div class="col-10 p-0" style="margin-top: -58px">
        <p class="h2 text-secondary m-3">
          <i
            style="font-size: 22px; color: #072648 !important"
            class="mdi mdi-send"
          ></i>
          <span style="font-size: 18px" class="font-weight-light ml-1"
            >Livrable :
          </span>
          <span style="font-size: 22px">{{ delivrable.name }}</span>
        </p>
      </div>
    </div>
    <hr class="mt-0" />
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-md-6 col-12">
            <div
              class="shdow"
              style="background-color: rgba(164, 164, 91, 0.5)"
            >
              <div
                class="
                  d-flex
                  flex-wrap
                  justify-content-between
                  align-items-center
                "
              >
                <p class="h2 text-secondary">Livrable</p>
                <a
                  :href="IMG_URL + delivrable.fileUrl"
                  class="text-secondary"
                  style="font-size: 30px"
                  target="_blank"
                  ><i class="mdi mdi-download"></i
                ></a>

                <hr class="w-100" />
              </div>

              <div class="row">
                <div class="col-md-6 col-12">
                  <div class="my-3">
                    <span class="font-weight-bold mr-3">Nom de livrables:</span>
                    <span>{{ delivrable.name }}</span>
                  </div>
                  <div class="my-3">
                    <span class="font-weight-bold mr-3">Tâche:</span>
                    <b-link
                      v-if="delivrable.task"
                      :to="{
                        name: 'show-task',
                        params: {
                          id: delivrable.task.id,
                          slug: delivrable.task.slug || 'abcde',
                        },
                      }"
                      class="d-inline-block my-auto text-truncate"
                      style="max-width: 150px"
                      >{{ delivrable.task.libelle }}</b-link
                    >
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="my-3">
                    <span class="font-weight-bold mr-3">Phase initiative:</span>
                    <span v-if="delivrable.task && delivrable.task.phase">{{
                      delivrable.task.phase.libelle
                    }}</span>
                  </div>
                  <div class="my-3">
                    <span class="font-weight-bold mr-3">Initiative:</span>
                    <b-link
                      v-if="delivrable.task && delivrable.task.phase"
                      :to="{
                        name: 'show-task',
                        params: {
                          id: delivrable.task.phase.initiatives.id,
                          slug:
                            delivrable.task.phase.initiatives.slug || 'abcde',
                        },
                      }"
                      >{{ delivrable.task.phase.initiatives.libelle }}</b-link
                    >
                  </div>
                </div>
              </div>
            </div>
            <br />

            <div
              class="shdow"
              style="background-color: rgba(107, 236, 144, 0.5)"
            >
              <div
                class="
                  d-flex
                  flex-wrap
                  justify-content-between
                  align-items-center
                "
              >
                <p class="h2 text-secondary">Vision</p>

                <hr class="w-100" />
              </div>

              <div
                class="row"
                v-if="
                  delivrable.task &&
                  delivrable.task.phase &&
                  delivrable.task.phase.initiatives
                "
              >
                <div
                  v-for="(objectif, index) in delivrable.task.phase.initiatives
                    .feuilleRoute.objectifs"
                  :key="index"
                  class="col-12 d-flex"
                >
                  <div v-if="index === 0" class="col-md-8 p-0 col-12">
                    <div class="my-3">
                      <span class="font-weight-bold mr-3">Nom de visions:</span>
                      <b-link
                        :to="{
                          name: 'show-vision',
                          params: {
                            id: objectif.vision.id,
                            slug: objectif.vision.slug || 'abcde',
                          },
                        }"
                        >{{
                          objectif.vision ? objectif.vision.libelle : "n/a"
                        }}</b-link
                      >
                    </div>
                  </div>
                  <div v-if="index === 0" class="col-md-4 p-0 col-12">
                    <div class="my-3">
                      <span class="font-weight-bold mr-3">Date:</span>
                      <span>{{
                        objectif.vision.createdAt
                          ? moment(
                              objectif.vision.createdAt,
                              "DD/MM/YYYY"
                            ).format("DD/MM/YYYY")
                          : "n/a"
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div class="col-md-6 col-12">
            <div
              class="shdow"
              style="background-color: rgba(103, 179, 183, 0.5)"
            >
              <div
                class="
                  d-flex
                  flex-wrap
                  justify-content-between
                  align-items-center
                "
              >
                <p class="h2 text-secondary">Feuille de route</p>

                <hr class="w-100" />
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="my-3">
                    <span class="font-weight-bold mr-3"
                      >Nom de feuille de route:</span
                    >
                    <b-link
                      v-if="
                        delivrable.task &&
                        delivrable.task.phase &&
                        delivrable.task.phase.initiatives &&
                        delivrable.task.phase.initiatives.feuilleRoute
                      "
                      :to="{
                        name: 'show-fdr',
                        params: {
                          id: delivrable.task.phase.initiatives.feuilleRoute.id,
                          slug:
                            delivrable.task.phase.initiatives.feuilleRoute
                              .slug || 'abcde',
                        },
                      }"
                      >{{
                        delivrable.task.phase.initiatives.feuilleRoute.name
                      }}</b-link
                    >
                  </div>
                  <div class="my-3">
                    <span class="font-weight-bold mr-3">Statut:</span>
                    <span
                      v-if="
                        delivrable.task &&
                        delivrable.task.phase &&
                        delivrable.task.phase.initiatives &&
                        delivrable.task.phase.initiatives.feuilleRoute &&
                        delivrable.task.phase.initiatives.feuilleRoute.status ==
                          'Terminé'
                      "
                      class="badge badge-success"
                    >
                      {{
                        delivrable.task.phase.initiatives.feuilleRoute.status
                      }}</span
                    >
                    <span v-else class="badge badge-Warning">
                      {{
                        delivrable.task.phase.initiatives.feuilleRoute.status
                      }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div class="shdow" style="background-color: #e6e3fc">
              <div
                class="
                  d-flex
                  flex-wrap
                  justify-content-between
                  align-items-center
                "
              >
                <p class="h2 text-secondary">Objectif</p>

                <hr class="w-100" />
              </div>

              <div
                class="row"
                v-if="
                  delivrable.task &&
                  delivrable.task.phase &&
                  delivrable.task.phase.initiatives
                "
              >
                <div
                  v-for="(objectif, index) in delivrable.task.phase.initiatives
                    .feuilleRoute.objectifs"
                  :key="index"
                  class="col-12 d-flex"
                >
                  <div class="my-3 p-0 col-md-8 col-12">
                    <span class="font-weight-bold mr-3"
                      >Nom de l'objectif {{ index + 1 }}:</span
                    >

                    <b-link
                      :to="{
                        name: 'show-objectif',
                        params: {
                          id: objectif.id,
                          slug: objectif.slug || 'abcde',
                        },
                      }"
                      >{{ objectif ? objectif.libelle : "n/a" }}</b-link
                    >
                  </div>
                  <div class="col-md-4 p-0 col-12">
                    <div class="my-3">
                      <span class="font-weight-bold mr-3">Description:</span>
                      <span>{{
                        objectif.desription ? objectif.desription : "n/a"
                      }}</span>
                    </div>
                  </div>
                </div>
                <!--    
                 -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { IMG_URL } from "@/helpers/services/api";

export default {
  props: {
    delivrable: Object,
  },
  data() {
    return {
      IMG_URL,
    };
  },
};
</script>

<style>
.sub-modal[data-v-23747a02] {
  background-color: white;
  padding: 20px 30px;
  border-radius: 10px;
  min-width: 80%;
  max-width: 80%;
  min-height: 95%;
  max-height: 95%;
  position: fixed !important;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  overflow-y: hidden;
  overflow-x: hidden;
  height: auto;
}
</style>
